import Footer from '@components/footer/Footer';
import { DependenciesProvider } from '@context/Dependencies.context';
import { SnapshotIdProvider } from '@context/SnapshotId.context';
import FiscalYearService from '@core/services/FiscalYearService.service';
import ProfileService from '@core/services/ProfileService.service';
import ReportService from '@core/services/ReportService.service';
import SnapshotService from '@core/services/SnapshotService.service';
import StockService from '@core/services/StockService.service';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './@components/header/Header';
import { SnapshotProvider } from './@context/Snapshot.context';
import { UserProvider } from './@context/User.context';
import ContactService from './@core/services/ContactService.service';
import './App.scss';
import { routes } from './routes.config';

function App(): React.ReactElement {
  const snapshotService = new SnapshotService();
  const reportService = new ReportService();
  const profileService = new ProfileService();
  const fiscalYearService = new FiscalYearService();
  const stockService = new StockService();
  const contactService = new ContactService();
  return (
    <DependenciesProvider
      snapshotService={snapshotService}
      reportService={reportService}
      profileService={profileService}
      fiscalYearService={fiscalYearService}
      stockService={stockService}
      contactService={contactService}
    >
      <UserProvider>
        <SnapshotIdProvider>
          <SnapshotProvider>
            <div className="App">
              <Header />
              <Switch>
                {routes.map(({ key, path, exact, component: Component }) => {
                  return (
                    <Route key={key} path={path} exact={exact}>
                      <Component />
                    </Route>
                  );
                })}
                <Route path="*">
                  <Redirect to="/" />
                </Route>
              </Switch>
              <Footer />
              <ToastContainer position="bottom-right" hideProgressBar autoClose={3000} />
            </div>
          </SnapshotProvider>
        </SnapshotIdProvider>
      </UserProvider>
    </DependenciesProvider>
  );
}

export default App;
