import { MarketComparison } from '@core/services/marketComparisonToTable';
import SnapshotLanguage from '@enum/SnapshotLanguage.enum';
import { ITableGridData } from '@interfaces/ITableGridData';
import SnapshotStatus from '../@enum/SnapshotStatus.enum';
import Contact from './contact';
import RejectReasons from './reject-reason';
import RevenueSource from './revenue-source';
import ShareHolder from './share-holder';
import { SnapshotAddOnBox } from './snapshot-add-on-box';
import { emptySnapshotCompany, SnapshotCompany } from './snapshot-company';
import { SnapshotVisibility } from './snapshot-visibility';
import StockPriceHistory from './stock-price-history';
import { StockStatistic } from './stock-statistic';
import { SnapshotAddOnBoxSequence } from './SnapshotAddOnBoxSequence';

export interface Snapshot {
  _touched?: boolean;
  id?: number;
  submittedBy: string;
  period: string;
  endOfPeriod: string;
  endOfPeriodTh: string;
  fiscalYear: string;
  availableLanguages: SnapshotLanguage[];
  company: SnapshotCompany;
  dataTh?: SnapshotData;
  dataEn?: SnapshotData;
  financialData?: SnapshotFinancialData;
  visibility?: SnapshotVisibility;
  status: SnapshotStatus;
  colorScheme?: string;
  marketComparisons?: MarketComparison[];
  stockStatistics?: StockStatistic[];
  thUrl?: string;
  enUrl?: string;
}

/**
 * SnapshotData
 * Data depending on submitted document language.
 */
export interface SnapshotData {
  profile?: string;
  remarkable?: string;
  revenueStructure?: RevenueSource[];
  progressSummary?: string;
  significantImprovement?: string;
  businessPlan?: string;
  consistencyPlan?: string;
  companyRating?: string;
  significantRiskManagement?: string;
  award?: string;
  shareHoldersBookClosingDate?: string;
  shareHolders?: ShareHolder[];
  stockPriceHistory?: StockPriceHistory;
  contact?: Contact;
  reason?: RejectReasons[];
  addOnLeft?: SnapshotAddOnBox;
  addOnRight?: SnapshotAddOnBox;
  boxSequence?: SnapshotAddOnBoxSequence;
}

export interface SnapshotFinancialData {
  incomeStatement?: ITableGridData;
  financialStatus?: ITableGridData;
  cashFlow?: ITableGridData;
  financialProportion?: ITableGridData;
}

export function emptySnapshot(): Snapshot {
  return {
    endOfPeriod: '',
    endOfPeriodTh: '',
    submittedBy: '',
    period: '',
    fiscalYear: '',
    availableLanguages: [],
    company: emptySnapshotCompany(),
    status: SnapshotStatus.DRAFT,
  };
}
