import ITranslation from '@interfaces/ITranslation';
import editPlaceholderIcon from '../../../@assets/icons/edit-placeholder.svg';

export const TranslationEn: ITranslation = {
  footerMessage: 'For inquiries, please contact SET-LINK@set.or.th or SET Contact Center Tel: 02-009-9888.',
  asOf: 'as of',
  close: 'Close',
  save: 'Save',
  confirm: 'Confirm',
  preview: 'Preview',
  back: 'Back',

  createCompanySnapshot: 'Create Listed Company Snapshot',
  companySnapshotManagement: 'Listed Company Snapshot Management',
  fiscalYear: 'Fiscal Year',
  viewHistoricalRecords: 'View history of all listed company snapshots',
  viewUnapprovedRecords: 'View unapproved listed company snapshots',
  selectFiscalYear: 'Select Fiscal Year',
  deleteReport: 'Delete report',
  documentDeletion: 'Document deletion',
  confirmDeleteReport: 'Are you sure you want to delete the Company Snapshot {{period}}/{{year}}?',
  createdBy: 'Created by',
  approvedBy: 'Approved by',
  approvedDate: 'on',
  continueDocumenting: 'Continue documenting',
  addThaiDocument: 'Add Thai Snapshot',
  addEnglishDocument: 'Add English Snapshot',
  confirmAddLanguageAfterSubmit:
    'ท่านต้องการเพิ่มการจัดทำฉบับ{{ lang1 }}ใช่หรือไม่\n\nทั้งนี้ หากดำเนินการดังกล่าว ระบบจะยกเลิกการนำส่งฉบับ{{ lang2 }} ที่อยู่ระหว่างรอผู้อนุมัติตรวจสอบ เพื่อท่านสามารถจัดทำทั้งสองภาษาและนำส่งให้ผู้อนุมัติตรวจสอบพร้อมกันอีกครั้งหนึ่ง',
  addLanguageDocument: 'ยืนยันเพิ่มการจัดทำฉบับ{{ language }}',
  manageDocument: 'Manage document',
  reviewDocument: 'Review document',
  previewDocument: 'Preview',
  theCompanyFillsInTheInformation: 'The company fills in the information',
  submissionStatusForApproval: 'Submission status for approval',
  notSubmitted: 'Not submitted',
  sentToApprover: 'Sent to approver',
  approvalStatusForPublication: 'Approval status for publication',
  waitingForApproval: 'Waiting for approval',
  notReviewed: 'Not reviewed',
  approved: 'Approved',
  reasonsForRejection: 'Reasons for rejection',
  approvalStatusForUnpublishing: 'Approval status for unpublishing',
  thai: 'TH',
  english: 'EN',
  and: ' & ',
  listedCompanySnapshot: 'Listed Company Snapshot',
  unpublished: 'Unpublished',
  all: 'All',

  goToThaiVersion: 'Go to Thai version',
  goToEnglishVersion: 'Go to English version',
  changeColorTheme: 'Change color theme',
  industry: 'Industry',
  sector: 'Sector',
  referencesForDataPreparation: 'References for data preparation',
  pleaseEnterTheBusinessDescription: 'Please enter the business description..',
  companyHighlight: 'Business Highlight',
  pleaseEnterTheCompanyHighlight: 'Please enter the company highlight..',
  revenueStructureByBusinessType: 'Revenue Structure',
  clickToAddRevenueStructure: `Click <img width="16" height="16" src=${editPlaceholderIcon}></img> to add revenue structure`,
  beginningOfDocument: 'Beginning of document',
  pageLengthLimitPage: 'Page length limit',
  exitFromDocument: 'Exit from the document',
  exitFromDocumentMessage: 'Are you sure you want to exit the document without saving it?',
  copyFromEnglish: 'Copy from English version',
  copyFromThai: 'Copy from Thai version',
  publishedOn: 'Published on DD/MM/YYYY',
  remarks:
    'Remarks: This document is prepared by the listed company and aimed to disseminate the listed company’s information to investors for only investment decision support. The listed company does not give investment advice or recommendation regarding the listed company’s securities. Before making investment decisions, investors should study additional information and seek advice from relevant professionals. In no event shall the listed company be responsible for any loss or damage arising from the use of the information contained herein. The listed company reserves the right to amend the content specified in this document without prior notice. Unless otherwise permitted by the listed company, copy, modification, or dissemination of this document or the content contained herein is prohibited. In case there is any questions regarding the listed company’s information, the investors may seek for additional information from the report or information which the listed company has disclosed through the Office of the Securities and Exchange Commission’s and/or the Stock Exchange of Thailand’s channel.',

  companyPerformanceTitle: 'Performance and Analysis {{period}}/{{year}}',
  retrieveDataFromSETDatabase: 'Retrieve data from SET database',
  dataRetrievalFromSETDatabase: 'Data retrieval from SET database',
  confirmRetrieveDataFromSETDatabase: 'Are you sure you want to retrieve the latest financial statements from SET database in order to replace the information on this table?',
  companyYearEndPerformanceTitle: 'Performance and Analysis {{year}}',
  statementOfComprehensiveIncome: 'Income Statement (MB)',
  unit: '',
  revenues: 'Revenues',
  costAndExpenses: 'Expenses',
  netProfit: 'Net Profit (Loss)',
  netInvestmentIncome: 'Net Investment Income',
  statementOfFinancialPosition: 'Balance Sheet (MB)',
  assets: 'Assets',
  liabilities: 'Liabilities',
  shareholdersEquity: "Shareholders' Equity",
  statementOfCashFlow: 'Cash Flow (MB)',
  netAssets: 'Net Assets',
  netAssetValuePerUnit: 'Net Assets Value per Unit (Unit:baht)',
  operating: 'Operating',
  investing: 'Investing',
  financing: 'Financing',
  netInvestmentIncomeToTotalIncomeRatio: 'Net Investment Income To Total Income',
  debtToAssetRatio: 'Debt To Asset Ratio',
  financialRatio: 'Financial Ratio',
  eps: 'EPS (Baht)',
  grossProfitMargin: 'GP Margin (%)',
  netProfitMargin: 'NP Margin (%)',
  netDe: 'D/E Ratio (x)',
  roe: 'ROE (%)',
  roa: 'ROA (%)',
  companyPerformanceSummary: 'Business Performance Summary',
  pleaseEnterThePerformanceSummary: 'Please enter the performance summary..',
  keyDevelopment: 'Key Milestones',
  pleaseEnterTheKeyDevelopment: 'Please enter the key milestones..',

  stockData: 'Stock Information',
  closingPrice: 'Closing price',
  pE: 'P/E (X)',
  pBv: 'P/BV (X)',
  dividendYield: 'Dividend yield (%)',
  listedShareWithUnit: 'Listed share (M)',
  parWithUnit: 'Par (Baht)',
  marketCapWithUnit: 'Market Cap (MB)',
  priceWithUnit: 'Price (B/Share)',
  cgReport: 'CG Report',
  companyRating: 'Company Rating',
  pleaseEnterTheCompanyRating: 'Please enter the company rating (Optional)..',

  businessProfile: 'Business Overview',
  businessPlanIn: 'Business Plan',
  pleaseEnterTheBusinessPlanIn: 'Please enter the business plan..',

  riskManagement: 'Risk Management Policy',
  pleaseEnterTheRiskManagement: 'Please enter the risk management policy..',

  majorShareholders: 'Major Shareholders',

  sustainabilityPolicyAndPlan: 'Sustainable Development Plan (Optional)',
  pleaseEnterTheSustainabilityPolicyAndPlan: 'Please enter the sustainable development plan..',

  awardsAndPastAchievements: 'Recent Awards and Recognitions (Optional)',
  pleaseEnterTheAwardsAndPastAchievements: 'Please enter the recent awards and recognitions..',

  companyContact: 'Company Information and Contact',
  website: 'Website',
  pleaseEnterTheWebsite: 'Please enter the website',
  companyEmail: 'Email',
  pleaseEnterTheEmail: 'Please enter the email',
  tel: 'Tel.',
  pleaseEnterTheTelephoneNumber: 'Please enter the telephone number',
  address: 'Address',
  pleaseEnterTheAddress: 'Please enter the address',
  otherTradingData: 'Other trading data',

  addRevenueStructure: 'Add revenue structure',
  addRevenueStructureDescription:
    'Please put the revenue structure data in order from the largest to the smallest amount. The data can be added up to {{maxItems}} items ("Others" included).',
  businessType: 'Business type',
  pleaseEnterTheBusinessType: 'Please enter the business type',
  revenueShare: 'Revenue share (%)',
  pleaseEnterTheRevenueShare: 'Please enter the revenue share (%)',
  others: 'Others',
  revenueShareWarning: 'The total revenue share exceeds 100%.',

  contactRelationShipManager: 'Contact Relationship Manager (RM)',
  fullName: 'Full name',
  officePhoneNumber: 'Office Phone Number',
  email: 'Email',

  companyNameThai: 'Company name (Thai)',
  companyNameEnglish: 'Company name (English)',
  accountingPeriod: 'Accounting period',
  pleaseSelectTheAccountingPeriod: 'Please select the accounting period',
  documentLanguage: 'Document language',
  thaiVersionOnly: 'Language : Thai',
  englishVersionOnly: 'Language : English',
  bothThaiAndEnglishVersionOnly: 'Lanugage : Thai and English',

  previewErrorPopupTitle: 'A problem with the preview',
  thaiVersionPreviewErrorPopupMessage: 'The system cannot display the preview because the document Thai version exceeds the page margin.',
  englishVersionPreviewErrorPopupMessage: 'The system cannot display the preview because the document English version exceeds the page margin.',
  bothThaiAndEnglishVersionPreviewErrorPopupMessage: 'The system cannot display the preview because the document Thai and English versions exceeds the page margin.',

  savedSnapshotSuccessfullyPopupTitle: 'Save Listed Company Snapshot',
  savedSnapshotSuccessfullyPopupMessage: 'The Listed Company Snapshot was successfully saved.',

  downloadDocument: 'Download',
  documentList: 'Document',
  thaiSnapshotTitle: 'Listed Company Snapshot {{- period}} Thai version',
  englishSnapshotTitle: 'Listed Company Snapshot {{- period}} English version',

  submitToApprover: 'Submit to approver',
  submitToApproverPopupTitle: 'เงื่อนไขการส่งให้ผู้อนุมัติเพื่อพิจารณาการเผยแพร่เอกสาร',
  submitToApproverPopupCheckbox: 'ตกลงยอมรับและยินยอมตามข้อตกลง',

  submitToApproverErrorPopupTitle: 'A problem with the Listed company snapshot submission',
  submitToApproverOnInvalidDateMessage:
    'Unable to submit the document to the approver. For completeness of the information, please submit again after the last day of {{- formattedPeriod}}.',

  unpublishDocument: 'Unpublish the document',
  unpublishDocumentPopupTitle: 'Unpublish the document',
  unpublishDocumentPopupMessageFirstLine:
    'Are you sure you want to send a request for unpublishing the Listed Company Snapshot {{- formattedPeriod}} from SET websites to approver?',
  unpublishDocumentPopupMessageSecondLine: 'This document will not be unpublished until approved by the approver.',

  amendPublish: 'Amend then publish again',
  amendPublishPopupTitle: 'Request for amendment',
  amendPublishPopupMessageFirstLine: 'Are you sure you want to create a new draft of the Listed Company Snapshot {{- formattedPeriod}} by duplicating the old one for amendment?',
  amendPublishPopupMessageSecondLine: 'When an amended document has been approved for publication, the system will automatically unpublish the old version.',

  cancelRequestForUnpublishDocument: 'Cancel the request for unpublishing the document',
  cancelRequestForUnpublishDocumentPopupTitle: 'Cancellation of the request for unpublishing the document',
  cancelRequestForUnpublishDocumentPopupMessage: 'Are you sure you want to cancel the request for unpublishing the Listed Company Snapshot {{- formattedPeriod}}?',

  reviewPublicationHeader: 'Please review and approve the publication of the Listed Company Snapshot',
  approve: 'Approve',
  reject: 'Reject',

  rejectionPublicationPopupTitle: 'Confirm the rejection of the publication',
  rejectionPublicationPopupMessage:
    'If you wish to reject the publication of the Listed Company Snapshot {{- formattedPeriod}} {{languageLabel}}, please provide reasons for rejection.',
  rejectionPublicationPopupReasonsLabel: 'Please provide reasons for rejection',

  approvePublicationPopupTitle: 'เงื่อนไขการอนุมัติการเผยแพร่เอกสาร',
  approvePublicationPopupCheckbox: 'ตกลงยอมรับและยินยอมตามข้อตกลง',

  reviewRequestUnpublishingHeader: 'Please review the request for unpublishing the document',

  approveRequestUnpublishingPublicationPopupTitle: 'The cancellation of publication',
  approveRequestUnpublishingPublicationPopupMessage: 'Are you sure you want to unpublish the Listed Company Snapshot {{- formattedPeriod}}?',

  rejectRequestUnpublishingPublicationPopupTitle: 'Reject the request for unpublishing the document',
  rejectRequestUnpublishingPublicationPopupMessage: 'Are you sure you want to reject the request for unpublishing the Listed Company Snapshot {{- formattedPeriod}}?',

  reviewPublicationOfAmendedHeader: 'Please review and approve the publication of the amended Listed Company Snapshot',

  publishedConflictPopupMessageFirstLine: 'เนื่องจากเอกสาร Listed Company Snapshot {{- formattedPeriod}} เคยผ่านการอนุมัติมาแล้วก่อนหน้านี้ โดยร่างเอกสารฉบับนี้เป็นฉบับแก้ไข',
  publishedConflictPopupMessageSecondLine: 'หากท่านอนุมัติเอกสารฉบับแก้ไขนี้ ระบบจะทำการยกเลิกการเผยแพร่เอกสาร Listed Company Snapshot ฉบับเดิม โดยอัตโนมัติ',

  unsubmitToTheApproverPopupTitle: 'Unsubmit to the approver',
  unsubmitToTheApproverPopupMessage: 'Are you sure you want to unsubmit to the approver?',

  unableToSendForApprovalTitle: 'Unable to send for approval since the company has not yet submitted the financial statement news.',
  unableToSendForApprovalMessage:
    'To ensure that the information in the Listed Company Snapshot does not exceed the information that the company has already disclosed to the SET. Please submit and disclose the financial statement news before submitting the Listed Company Snapshot to the approver.',
  previewImageTitle: 'Preview Image',
  pleaseEnter: 'Please enter information..',
  pleaseEnterTitle: 'Insert title....',

  shareholderName: 'Shareholder’s name',
  percentage: 'Percentage (%)',
  plaseEnterNameShareholder: 'Please enter name shareholder',
  plaseEnterPercentageShareholder: 'Please enter',
  shareHolderWarning: 'The total percentage shareholder exceeds 100%.',
  shareHolderDescription: 'Please put the shareholder structure data in order from the largest to the smallest amount.',
  asOfIn: 'Data as of',
  shareHolderNameInvalid: 'Please fill out the information completely.',
  majorShareholdersIn: 'Top 5 Major Shareholders',
  downloadPdfError: 'System error, please try again.',
};
