enum SnapshotAction {
  INITIAL_SNAPSHOT = 'InitialSnapshot',
  UPDATE_COMPANY = 'UpdateCompany',
  UPDATE_META = 'UpdateMeta',
  UPDATE_PROFILE_TH = 'UpdateProfileTh',
  UPDATE_PROFILE_EN = 'UpdateProfileEn',
  UPDATE_REMARKABLE_TH = 'UpdateRemarkableTh',
  UPDATE_REMARKABLE_EN = 'UpdateRemarkableEn',
  UPDATE_REVENUE_STRUCTURE_TH = 'UpdateRevenueStructureTh',
  UPDATE_REVENUE_STRUCTURE_EN = 'UpdateRevenueStructureEn',
  UPDATE_INCOME_STATEMENT = 'UpdateIncomeStatement',
  UPDATE_FINANCIAL_STATUS = 'UpdateFinancialStatus',
  UPDATE_CASH_FLOW = 'UpdateCashflow',
  UPDATE_FINANCIAL_PROPORTION = 'UpdateFinancialProportion',
  UPDATE_PROGRESS_SUMMARY_TH = 'UpdateProgressSummaryTh',
  UPDATE_PROGRESS_SUMMARY_EN = 'UpdateProgressSummaryEn',
  UPDATE_SIGNIFICANT_IMPROVEMENT_TH = 'UpdateSignificantImprovementTh',
  UPDATE_SIGNIFICANT_IMPROVEMENT_EN = 'UpdateSignificantImprovementEn',
  UPDATE_BUSINESS_PLAN_TH = 'UpdateBusinessPlanTh',
  UPDATE_BUSINESS_PLAN_EN = 'UpdateBusinessPlanEn',
  UPDATE_CONSISTENCY_PLAN_TH = 'UpdateConsistencyPlanTh',
  UPDATE_CONSISTENCY_PLAN_EN = 'UpdateConsistencyPlanEn',
  UPDATE_SIGNIFICANT_RISK_MANAGEMENT_TH = 'UpdateSignificantRiskManagementTh',
  UPDATE_SIGNIFICANT_RISK_MANAGEMENT_EN = 'UpdateSignificantRiskManagementEn',
  UPDATE_AWARD_TH = 'UpdateAwardTh',
  UPDATE_AWARD_EN = 'UpdateAwardEn',
  UPDATE_SHARES_TH = 'UpdateSharesTh',
  UPDATE_SHARES_EN = 'UpdateSharesEn',
  UPDATE_CLOSING_DATE_TH = 'UpdateClosingDateTh',
  UPDATE_CLOSING_DATE_EN = 'UpdateClosingDateEn',
  UPDATE_CONTACT_TH = 'UpdateContactTh',
  UPDATE_CONTACT_EN = 'UpdateContactEn',
  UPDATE_COMPANY_RATING_TH = 'UpdateCompanyRatingTh',
  UPDATE_COMPANY_RATING_EN = 'UpdateCompanyRatingEn',
  UPDATE_COMPANY_LOGO = 'UPDATE_COMPANY_LOGO',
  UPDATE_CONSISTENCY_PLAN_VISIBILITY = 'UpdateConsistencyPlanVisibility',
  UPDATE_AWARD_VISIBILITY = 'UpdateAwardVisibility',
  UPDATE_COMPANY_RATING_VISIBILITY = 'UpdateCompanyRatingVisibility',
  UPDATE_COLOR_SCHEME = 'UpdateColorScheme',
  UPDATE_MARKET_COMPARISONS = 'UpdateMarketComparisons',
  UPDATE_STOCK_STATISTICS = 'UpdateStockStatistics',
  MAKE_UNTOUCHED = 'MakeDataUntouched',
  UPDATE_ADD_ON_LEFT_VISIBILITY = 'UpdateAddOnBox1Visibility',
  UPDATE_ADD_ON_RIGHT_VISIBILITY = 'UpdateAddOnBox2Visibility',
  UPDATE_ADD_ON_LEFT_TH = 'UpdateAddOnBox1Th',
  UPDATE_ADD_ON_LEFT_EN = 'UpdateAddOnBox1En',
  UPDATE_ADD_ON_RIGHT_TH = 'UpdateAddOnBox2Th',
  UPDATE_ADD_ON_RIGHT_EN = 'UpdateAddOnBox2En',
  UPDATE_ADD_ON_SEQUENCE_TH = 'UpdateAddOnSequenceBoxTh',
  UPDATE_ADD_ON_SEQUENCE_EN = 'UpdateAddOnSequenceBoxEn',
}

export default SnapshotAction;
