import { Profile } from '@models/profile';
import React, { createContext, useReducer, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import TokenService from '../@core/services/token.service';
import { useDependencies } from './Dependencies.context';

export type ActionType = 'SET';
type Action = { type: ActionType; payload?: Profile };
type Dispatch = (action: Action) => void;
type State = Profile | undefined;

const UserStateContext = createContext<State | undefined>(undefined);
const UserDispatchContext = createContext<Dispatch | undefined>(undefined);

function reducer(state: State, action: Action): State {
  if (action.type === 'SET') {
    return action.payload ? { ...action.payload } : state;
  }
  throw new Error(`Unhandled action type: ${action.type}`);
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const UserProvider: React.FC = ({ children }: { children?: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, undefined);
  const { profileService } = useDependencies();
  const query = useQuery();
  const [isInitial, setIsInitial] = useState(false);

  useEffect(() => {
    if (!isInitial) {
      setIsInitial(true);
      const token: string | null = query.get('JWT');

      if (token) {
        TokenService.setToken(token);
      }

      if (TokenService.getToken()) {
        profileService
          .get()
          .then((profileResponse: Profile) => {
            dispatch({
              type: 'SET',
              payload: profileResponse,
            });
          })
          .catch((error) => {
            toast.error(error.data);
            if (error.status == 401) {
              TokenService.clearToken();
            }
          });
      }
    }
  }, [profileService, query, isInitial]);
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

const useUserState = (): State => {
  return React.useContext(UserStateContext);
};
const useUserDispatch = (): Dispatch => {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
};

export { UserProvider, useUserState, useUserDispatch };
